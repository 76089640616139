import config from "config"
import { getName, getUserName, getMedia } from "./format"

export const image = (path) => path ? `${config.storage}${path}` : ""

/**
 * Photo adapter for grids, previews, fullscreen
 */
export const photo = (attrs, useWatermarkPreview = true) => {
    return {
        self: attrs,
        id: attrs.ID,
        date: new Date(parseInt(attrs.Metadata.EXIF.Time) * 1000),
        preview: useWatermarkPreview ? image(attrs.PreviewWatermaked || attrs.PreviewWatermarked) : image(attrs.Preview),
        thumbnail: image(attrs.Thumbnail),
        embedThumbnail: attrs.EmbedThumbnail,
        original: image(attrs.Original),
    }
}

/**
 * Place adapter for cards, autocompletes
 */
export const place = (attrs) => {
    const placeName = getName(attrs.Name)

    return {
        value: attrs.ID,
        id: attrs.ID,
        text: placeName,
        name: placeName,
        image: image((attrs.Avatar || {}).Cropped),
        wallpaper: image((attrs.Wallpaper || {}).Cropped),
        self: attrs,
        subtitle: "", // subtitle for autocomplete, example: place city
    }
}


/**
 * City adapter for cards, autocompletes
 */
export const city = (attrs) => {
    const cityName = getName(attrs.Name)

    return {
        value: attrs.ID,
        id: attrs.ID,
        name: cityName,
        text: cityName,
        self: attrs,
        subtitle: "", // subtitle for autocomplete, example: city country
    }
}

/**
 * User adapter for cards, autocompletes
 */
export const user = (attrs) => {
    const name = getUserName(attrs.Name)

    return {
        value: attrs.ID,
        id: attrs.ID,
        text: name,
        name: name,
        image: image(attrs.Avatar.Cropped),
        self: attrs,
        subtitle: "", // subtitle for autocomplete, example: place city
    }
}

export const photos = (photos, useWatermarkPreview = true) => photos.map(item => photo(item, useWatermarkPreview))

/**
 * Photoreport adapter
 */
export const photoreport = (attrs, useWatermarkPreview = true) => {
    return {
        id: attrs.ID,
        photos: photos(attrs.Photos || [], useWatermarkPreview),// attrs.Photos.length === 0 ? [] : photos(attrs.Photos, useWatermarkPreview),
        self: attrs,
        name: getName(attrs.Name),
        place: place(attrs.Place || {}),
        cost: parseFloat(attrs.Cost || 0),
        preview: getMedia(attrs.ThumbnailID, "default"),
    }
}

/**
 * Photogroup adapter
 */
export const photogroup = (attrs, useWatermarkPreview = true) => {
    const { StartTime, EndTime } = attrs.Criterias
    return {
        id: attrs.ID,
        photos: photos(attrs.Photos, useWatermarkPreview),
        self: attrs,
        place: place(attrs.Place),
        photographer: user(attrs.Photographer),
        startTime: StartTime,
        endTime: EndTime,
        qrs: attrs.Features.QRs,
    }
}

/**
 * Order contents adapter
 */
export const order = (order) => {
    const contents = order.Contents.map((item) => {
        if (item.Content === "Photo") {
            return { content: "photo", photo: photo(item.Photo, false) }
        } else {
            return { content: "photoreport", photoreport: photoreport(item.Report, false) }
        }
    })
    return Object.assign({}, order, { Contents: contents })
}

/**
 * Fixed currency
 */
export const currency = (value, digits = 2) => {
    if (parseInt(value) === value) {
        return value
    }


    return parseFloat(value.toFixed(digits))
}

export const photogroups = list => list.map(item => photogroup(item))
export const photoreports = list => list.map(item => photoreport(item))

export default {
    photo,
    photos,
    photoreport,
    photogroup,
    photogroups,
    place,
    order,
    user,
    currency,
    city,
}
