<template>
    <v-container class="lightbox-panel">
        <div class="heading lightbox-panel__text">
            {{ $t(`headers.photographer`) }} {{ photo.Photographer.Name | getName }}
        </div>
        <h4 class="lightbox-panel__date lightbox-panel__text">
            {{ photo.Metadata.EXIF.Time | unixDateTime }}
        </h4>
        <v-divider class="lightbox-panel__divider" dark></v-divider>
        <v-row class="" dense>
            <v-col cols="6" class="left">
                <p class="lightbox-panel__text">{{ $t(`rudiments['file type']`) }}</p>
            </v-col>
            <v-col cols="6" class="left">
                <p class="font-weight-black lightbox-panel__text">{{photo.OriginalName | getExtension}}</p>
            </v-col>
            <v-col cols="6" class="left">
                <p class="lightbox-panel__text">{{ $t(`rudiments['file res']`) }}</p>
            </v-col>
            <v-col cols="6" class="left">
                <p class="font-weight-bold lightbox-panel__text">
                    {{photo.Metadata.EXIF.Width}}X{{photo.Metadata.EXIF.Height}}</p>
            </v-col>
            <v-col cols="6" class="left">
                <p class="lightbox-panel__text">{{ $t(`rudiments['file size']`) }}</p>
            </v-col>
            <v-col cols="6" class="left">
                <p class="font-weight-bold lightbox-panel__text">{{photo.OriginalSize | getMB}}</p>
            </v-col>
        </v-row>
        <v-divider dark class="lightbox-panel__divider"></v-divider>
    </v-container>
</template>

<script>
export default {
    props: {
        photo: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    }
}
</script>

<style lang="stylus">
    .lightbox-panel {
        color #fff
        height 100%
        font-family: "Roboto", sans-serif;

        p {
            margin 0
            padding 0
        }
    }

    .lightbox-panel__divider {
        margin 10px 0
    }

    .lightbox-panel__text {
        font-size 16px
    }

    .lightbox-panel__date {
        margin 15px 0
    }
</style>
